.header {
    background-color: aquamarine;
}

.header-item {
    padding: 1em;
    cursor: pointer;
}

.header-item:hover {
    opacity: 0.5;
}