.task {
    /* background-color: aquamarine; */
    padding: 0.08em;
}

.task-title {
    /* min-width: 30em; */
    flex: 1;
    margin-right: 1em;
    cursor: pointer;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    float: left;
}

.task-status {
    color: white;
    background-color: #33D391;
    min-width: 8em;
    text-align: center;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    cursor: pointer;
}

.task-new {
    background-color: #4e506e;
    padding: 0.5em;
    margin: 0.2em;
    color: white;
    border: 0;
    width: 100%;
}

.task:hover {
    background-color: #646796;
}