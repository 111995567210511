.task-page {
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
    padding: 2em;
    color: #c3c6d4;
    background-color: #30324E;
    border-radius: 0.5em;
    height: 100%;
}

.task-comment-new {
    background-color: #4e506e;
    padding: 0.5em;
    color: white;
    border: 0;
    width: 100%;
}