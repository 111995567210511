.group {
    margin-left: 2em;
    margin-top: 2em;
    display: flex;
    color: #c3c6d4;
    background-color: #30324E;
}

.group-title {
    display: flex;
    align-items: center;
    color: rgb(255, 62, 62);
}

.group-title-arrow {
    cursor: pointer;
    margin-right: 0.5em;
}

.group-left {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    background-color: red;
    width: 2px;
    min-height: 2px;
    padding-right: 0.4em;
}

.group-body {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}